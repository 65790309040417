import { defineComponent } from 'vue';
import { TxtWeight } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { useAddPhoto } from 'composables/addPhoto';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { PropType } from '@vue/runtime-core';
import { FileSource } from 'core/types';
import { usePageLoader } from 'composables/pageLoader';
import { useApiErrors } from 'composables/apiErrors';
import { useCropper } from 'modules/cropper';

const AddPhotos = defineComponent({
  props: {
    photos: {
      type: Array as PropType<FileSource[]>,
      required: true,
    },
    private: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load'],
  setup(props, { emit }) {
    const store = useStore();
    const { cropImage } = useCropper();

    const { startLoading, endLoading, increment } = usePageLoader();
    const { showError } = useApiErrors();

    const {
      onSave,
    } = useAddPhoto(props.private, {
      onUploaded: () => {
        endLoading();
        emit('load');
      },
      onUploadProgress: (progress) => {
        increment(progress);
      },
      onStartUploading: () => {
        startLoading();
      },
    });

    async function removePhoto(key: number) {
      const item = props.photos[key];
      if (item && item.id) {
        try {
          await store.dispatch(`${USER_STORE_KEY}/${UsersAction.DeletePhoto}`, item.id);
        } catch (e) {
          showError(e);
        }
      }
    }

    const selectAsMain = async (item: FileSource) => {
      if (props.private) return;
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.SetAsMain}`, item.id);
      } catch (e) {
        showError(e);
      }
    };

    function onFileInputChange(event: Event) {
      const target = event.target as HTMLInputElement;
      if (!target) return;
      const _file = target?.files?.[0];
      if (!_file) return;
      cropImage({
        image: URL.createObjectURL(_file),
        onCrop: onSave,
      });
      target.value = '';
    }

    return {
      removePhoto,
      selectAsMain,
      onSave,
      onFileInputChange,

      TxtWeight,
      IconType,
      IconName,
      Size,
      MainColor,
    };
  },
});

export default AddPhotos;
