<template>
  <div class="add-photos">
    <div
      v-if="photos.length < 10"
      class="add-photos__upload"
    >
      <icon
        :type="IconType.Contour"
        :name="IconName.Plus"
        :size="Size.M"
        :color="MainColor.Grey5"
      />
      <input
        ref="fileInputRef"
        type="file"
        accept="image/*"
        @change="onFileInputChange"
      >
    </div>
    <div
      v-for="(item, i) in photos"
      :key="item.id"
      class="add-photos__item"
      :class="{
        'add-photos__item_active': !private && item.main
      }"
      @click="selectAsMain(item)"
    >
      <img
        :src="item.path"
        class="main-slider__image"
      >
      <a
        class="add-photos__remove"
        @click.prevent.stop="removePhoto(i)"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xxs"
        />
      </a>

      <template v-if="!private">
        <div
          v-if="item.main"
          class="add-photos__btn_active"
        >
          {{ $t('pages.profile.addPhoto.mainPhoto') }}
        </div>
        <div
          v-else
          class="add-photos__btn"
        >
          {{ $t('pages.profile.addPhoto.selectAsMain') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" src="./AddPhotos.ts" />
<style lang="scss" src="./addPhotos.scss" />
