<template>
  <div class="add-contacts">
    <div
      v-for="(value, i) in (model || [])"
      :key="`edit-contact-${i}`"
      class="add-contacts__row-contact"
    >
      <div :class="`add-contacts__row-contact-icon add-contacts__row-contact-icon_${value.type}`">
        <icon
          :type="IconType.Contour"
          :name="contactTypeMap[value.type]"
          :size="Size.Xs"
        />
      </div>

      <template v-if="value.type === ContactType.Address">
        <div class="add-contacts__row-contact-input add-contacts__row-contact-autocomplete">
          <autocomplete
            :id="`edit-contact-${i}`"
            :key="`edit-contact-${i}-input`"
            v-model="model[i].contact"
            :placeholder="$t('pages.profile.addressPlaceholder')"
            :items="addresses"
            regular
            teleport="#modals"
            scroll-body="#modals"
            @focus="startAddressSearch(model[i].contact, i)"
            @update:model-value="(v) => onAddressUpdate(v, i)"
            @update:search="(v) => {
              model[i].contact = v;
              startAddressSearch(v, i);
            }"
          >
            <template #item="{ item }">
              <div class="add-contacts__city-item">{{ item }}</div>
            </template>
          </autocomplete>
        </div>
      </template>
      <template v-else-if="value.type === ContactType.Telegram">
        <span class="text-dark-grey mr-px">@</span>
        <input
          :id="`edit-contact-${i}`"
          :key="`edit-contact-${i}-input`"
          v-model="model[i].contact"
          :placeholder="$t('pages.profile.telegramPlaceholder')"
          class="add-contacts__row-contact-input"
          type="text"
          inputmode="text"
        >
      </template>
      <textfield
        v-else
        :id="`edit-contact-${i}`"
        :key="`edit-contact-${i}-input`"
        v-model="model[i].contact"
        :style="TextfieldStyle.Usual"
        class="add-contacts__row-contact-input"
        :mask="phoneMask"
        type="tel"
      />

      <div :class="`add-contacts__row-contact-icons`">
        <icon-btn
          :size="Size.Xs"
          :color="MainColor.Grey5"
          class="add-contacts__row-contact-edit"
          @click="handleFocusContact(i)"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Pen"
            :size="Size.Xs"
          />
        </icon-btn>

        <icon-btn
          :size="Size.Xs"
          :color="MainColor.Grey5"
          class="add-contacts__row-contact-trash"
          @click.stop="handleRemoveContact(i)"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Trash"
            :size="Size.Xs"
          />
        </icon-btn>
      </div>
    </div>

    <div>
      <div class="add-contacts__contact-add">
        <btn
          :type="BtnType.Text"
          @click="handleAddContact(ContactType.Telegram)"
        >
          + {{ $t('pages.profile.form.contacts.add.telegram') }}
        </btn>
      </div>

      <div class="add-contacts__contact-add">
        <btn
          :type="BtnType.Text"
          @click="handleAddContact(ContactType.WhatsApp)"
        >
          + {{ $t('pages.profile.form.contacts.add.whatsApp') }}
        </btn>
      </div>

      <div class="add-contacts__contact-add">
        <btn
          :type="BtnType.Text"
          @click="handleAddContact(ContactType.Phone)"
        >
          + {{ $t('pages.profile.form.contacts.add.phone') }}
        </btn>
      </div>

      <div class="add-contacts__contact-add">
        <btn
          :type="BtnType.Text"
          @click="handleAddContact(ContactType.Address)"
        >
          + {{ $t('pages.profile.form.contacts.add.address') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./AddContacts.ts" />
<style lang="scss" src="./addContacts.scss" />
