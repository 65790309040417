import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { IUser, UserStatus } from 'api/users/models/user';

export function useUser() {
  const { t } = useI18n();

  function getUserStatus(user: IUser) {
    if (user.deleted_at) return t('user.profileDeleted');
    const isOnline = user.is_online;
    const onlineAt = user.online_at ? dayjs(user.online_at) : undefined;
    if (isOnline) {
      return t(`user.${UserStatus.Online}.short`);
    }
    if (onlineAt) {
      return Math.ceil(dayjs().diff(onlineAt, 'd', true)) <= 3
        ? t(`user.onlineAt.${user.gender}`, { d: onlineAt.fromNow() })
        : t(`user.wasRecently.${user.gender}`);
    }
    return t(`user.${UserStatus.Offline}.short`);
  }

  function getInterestsString(interests: string[] | null | undefined) {
    return interests
      ?.map(x => t(`user.interest.${x}`))
      .join(', ')
      .toLowerCase() ?? '';
  }

  function getPurposeString(purpose: string[] | null | undefined) {
    return purpose
      ?.map(x => t(`user.purpose.${x}`))
      .join(', ')
      .toLowerCase() ?? '';
  }

  function getActivityString(activity: string[] | null | undefined) {
    return activity
      ?.map(x => t(`user.field.${x}`))
      .join(', ')
      .toLowerCase() ?? '';
  }

  return {
    getUserStatus,
    getInterestsString,
    getPurposeString,
    getActivityString,
  };
}
