import { computed, defineComponent, ref, watch } from 'vue';
import { Nullable } from 'core/types';
import { Contact, ContactType, IContact } from 'api/users/models/contact';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { TextfieldStyle } from 'components/Textfield';
import { BtnType } from 'components/Btn';
import { useI18n } from 'vue-i18n';
import { useSearchAddresses } from 'composables/searchAddresses';
import Autocomplete from 'components/Autocomplete';
import { UsersGetter, useUsersGetter } from 'store/users';
import { clone } from 'ramda';
import { IProfile } from 'api/users/models/user';

const AddContacts = defineComponent({
  components: {
    Autocomplete,
  },
  emits: ['valid'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const phoneMask = computed(() => t('mask.phone'));
    const profileGetter = useUsersGetter<IProfile>(UsersGetter.Profile);
    const model = ref<Nullable<IContact[]>>(clone(profileGetter?.contacts));
    watch(() => profileGetter.contacts, (val) => {
      model.value = val;
    });
    watch(model, (model) => {
      if (model) {
        const contacts: IContact[] = model.filter(
          (contact) => {
            if (!contact.contact) return false;
            if (contact.type === ContactType.Phone || contact.type === ContactType.WhatsApp) {
              return contact.contact.length >= phoneMask.value.length;
            }
            return contact.contact.trim().length > 0;
          },
        );

        emit('valid', contacts);
      }
    }, { deep: true });

    const { search: addressSearch, items: addressesData } = useSearchAddresses();
    const addresses = computed(() => addressesData.value.map((item) => item.value));

    function startAddressSearch(val: string, i: number) {
      addressSearch.value = val;
      onAddressUpdate(val, i);
    }

    function onAddressUpdate(val: string, i: number) {
      const addressData = addressesData.value.find((item) => item.value === val);
      const lat = addressData?.data?.geo_lat;
      const lng = addressData?.data?.geo_lon;
      if (model.value) {
        if (lat && lng) {
          model.value[i].geolocation = {
            lat,
            lng,
          };
        } else {
          model.value[i].geolocation = undefined;
        }
      }
    }

    const handleFocusContact = (i: number) => {
      (document.querySelector(`#edit-contact-${i}`) as HTMLElement)?.focus();
    };
    const handleRemoveContact = (i: number) => {
      model.value?.splice(i, 1);
    };
    const handleAddContact = (type: ContactType) => {
      const contact = new Contact({
        type,
        contact: '',
      });
      if (!model.value) {
        model.value = [contact];
      } else {
        model.value.push(contact);
      }
    };

    const contactTypeMap = {
      [ContactType.Phone]: IconName.Phone,
      [ContactType.Telegram]: IconName.Telegram,
      [ContactType.WhatsApp]: IconName.Whatsapp,
      [ContactType.Address]: IconName.Pin,
    };

    return {
      model,
      phoneMask,

      addresses,
      startAddressSearch,
      onAddressUpdate,

      handleAddContact,
      handleRemoveContact,
      handleFocusContact,

      contactTypeMap,

      IconType,
      IconName,
      Size,
      ContactType,
      TextfieldStyle,
      BtnType,
      MainColor,
    };
  },
});

export default AddContacts;
