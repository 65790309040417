import { ref, watch } from 'vue';
import { Api } from 'api/api';
import { debounce } from 'core/helpers';

export function useSearchAddresses(initSearch = false) {
  const search = ref<string>();
  const items = ref<any[]>([]);

  const debouncedSearch = debounce(searchAddresses, 250);

  watch(search, (val) => {
    debouncedSearch(val);
  });

  async function searchAddresses(search?: string) {
    if (!search) return;

    const response = await Api.dadataService.searchAddresses(search);
    const { suggestions } = response.data;
    items.value = suggestions || [];
  }

  if (initSearch) {
    searchAddresses();
  }

  return {
    search,
    items,

    searchAddresses,
  };
}
