import { computed } from 'vue';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';

type Opts = {
  onUploadProgress?: (progress: number) => void;
  onUploaded?: () => void;
  onStartUploading?: () => void;
}

export function useAddPhoto(isPrivate = false, opts?: Opts) {
  const store = useStore();
  const { t } = useI18n();

  const profile = computed<Nullable<IProfile>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
  const privatePhotosLength = computed(() => profile.value?.private_photos.length);
  const photosLength = computed(() => profile.value?.photos.length);

  function onSave(blob: Blob) {
    if (isPrivate && privatePhotosLength.value && privatePhotosLength.value >= 10) {
      toastEmitter.emit('toast', {
        type: ToastType.Error,
        message: t('components.cropImage.upToN', { n: 10 }),
      });
      return;
    }
    if (!isPrivate && photosLength.value && photosLength.value >= 10) {
      toastEmitter.emit('toast', {
        type: ToastType.Error,
        message: t('components.cropImage.upToN', { n: 10 }),
      });
      return;
    }
    uploadPhoto(blob);
  }

  async function uploadPhoto(blob: Blob) {
    try {
      const file = new File([blob], (profile.value?.name || '') + Date.now());
      if (opts?.onStartUploading) opts.onStartUploading();
      if (!isPrivate) {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.AddPhoto}`,
          { photo: file, onUploadProgress: opts?.onUploadProgress });
      } else {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.AddPrivatePhoto}`,
          { photo: file, onUploadProgress: opts?.onUploadProgress });
      }
    } catch (error) {
      toastEmitter.emit('toast', {
        type: ToastType.Error,
        message: t('errors.api.photoUploadError'),
      });
    } finally {
      if (opts?.onUploaded) opts.onUploaded();
    }
  }

  return {
    onSave,
  };
}
